$color-background: #505050;

$color-boy: #66B1D6;
$color-girl: #E1969E;

$color-text: #f1f1f1;

$primary: $color-text;

.color-girl {
  color: $color-girl;
}

.color-boy {
  color: $color-boy;
}

.background-heart {
  background: url("../Assets/background-heart.svg");
}
