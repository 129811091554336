@import '../../_Scss/Default';

.GenerateLinkForm-Form-Label {
  cursor: pointer;
  opacity: 0.4;

  &:hover {
    opacity: 0.7;
  }
}

.GenerateLinkForm-Form-InputRadio {
  margin:0;padding:0;
  -webkit-appearance:none;
  -moz-appearance:none;
  appearance:none;

  &:focus + .GenerateLinkForm-Form-Label {
    opacity: 0.7;
  }

  &:checked + .GenerateLinkForm-Form-Label {
    opacity: 1;
  }
}

