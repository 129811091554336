@import '../../_Scss/Default';

.ScratchCard {
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.ScratchCard-Background {
    position:relative;
}

.ScratchCard-Background-Image {
    max-width: 100%;
    max-height: 100vh;
    user-select: none;
}

.ScratchCard-ScratchFieldContainer {
    position: absolute;
    top: 43%;
    margin: auto;
    width: 50%;
    left: 0;
    right: 0;
}

.ScratchCard-ScratchFieldContainer canvas {
    position: absolute;
    left: 0;
    top: 0;
}

.ScratchCard-ScratchFieldContainer-Heart {
    position: relative;
    border-radius: 50%;
    margin: 2px;
    background-color: #FEFEFE;

    &::after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }
}

.ScratchCard-ScratchFieldContainer-Heart-Icon {
    position: absolute;
    width: 60%;
    top: 53%;
    left: 50%;
    transform: translate(-50%, -50%);

    &.ScratchCard-ScratchFieldContainer-Heart-Icon-Boy {
        color: $color-boy;
    }

    &.ScratchCard-ScratchFieldContainer-Heart-Icon-Girl {
        color: $color-girl;
    }
}

.ScratchCard-ScratchFieldContainer-LinkGift {
    position: absolute;
    bottom: 0;
    background-color: $color-text;
    border-radius: $border-radius;
    text-align: center;
    padding: 15px;
    margin: 0 5px 5px;
    left: 0;
    right: 0;
    color: $color-background;
    text-decoration: none;
    user-select: none;

    &:hover {
        text-decoration: none;
        color: darken($color-background, 20%);
        background-color: darken($color-text, 3%);

    }
}
