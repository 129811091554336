@import "./Colors";
@import "./Font";


// colors
$body-bg: $color-background;
$body-color: $color-text;
$yiq-text-light: $color-text;
$yiq-text-dark: $color-background;

// font
$font-family-base: 'WriteSans', -apple-system, "Helvetica Neue", Arial, sans-serif;
$link-color: $color-text;
$link-decoration: bold;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: underline !default;
$font-weight-bolder: 900;

// btn
$btn-border-radius-lg: 25px;

// modal
$modal-content-bg: $color-background;
$modal-content-border-width: 0px;
$modal-content-border-radius: 20px;

// misc
$border-radius: 20px !default;

@import "~bootstrap/scss/bootstrap";
